import { put, take } from 'redux-saga/effects';
import { appActions } from '../actions/app';
import { authenticationActions } from '../actions/authentication';

function* watchAppStartSaga() {
  yield take(appActions.appStart);
  yield put(authenticationActions.loadCurrentUser());
  yield take([
    authenticationActions.loadCurrentUserSuccess,
    authenticationActions.loadCurrentUserFailure,
  ]);
  yield put(appActions.appStartSuccess());
}

export default watchAppStartSaga;
