import { createTheme } from '@mui/material/styles';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';
declare module '@mui/material/styles' {
  interface Palette {
    feedbackError: Palette['primary'];
    feedbackNeutral: Palette['primary'];
    feedbackProcessing: Palette['primary'];
    feedbackSuccess: Palette['primary'];
    feedbackInactive: Palette['primary'];
  }

  interface PaletteOptions {
    feedbackError: PaletteOptions['primary'];
    feedbackNeutral: PaletteOptions['primary'];
    feedbackProcessing: PaletteOptions['primary'];
    feedbackSuccess: PaletteOptions['primary'];
    feedbackInactive: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  typography: {
    body1: {
      fontSize: '0.875rem',
    },
  },
  palette: {
    error: {
      main: red[500],
    },
    warning: {
      main: orange[500],
    },
    feedbackNeutral: {
      main: '#CCDAF5',
    },
    feedbackError: {
      main: red[100],
    },
    feedbackProcessing: {
      main: '#FDF3D0',
    },
    feedbackSuccess: {
      main: '#DCE9D5',
    },
    feedbackInactive: {
      main: '#ebd8d8',
    },
  },
});

export type Theme = typeof theme;

export default theme;
