import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { adminApi } from 'core/api/adminApi';
import reducers from '../reducers';
import sagas from '../sagas';

const apisReducer = {
  [adminApi.reducerPath]: adminApi.reducer,
};

const sagaMiddleware = createSagaMiddleware();

// Create store
export const store = configureStore({
  reducer: combineReducers({ ...reducers, ...apisReducer }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware).concat(adminApi.middleware),
});

sagaMiddleware.run(sagas);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAppSelector: TypedUseSelectorHook<any> = useSelector;

export default configureStore;
