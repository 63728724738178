import awsmobile from 'aws-exports';

const getEnvironment = () => {
  switch (awsmobile.environment) {
    case 'production':
      return 'prod';
    default:
      return 'dev';
  }
};
export const env = getEnvironment();

export const baseUrl = `https://${env}.stackwellapi.com/`;
