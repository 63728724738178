import { AdminBuild, TagTypes } from './createApi';
import {
  Campaign,
  PaginationKey,
  PaginatedQuery,
  PaginatedResult,
  CampaignTexts,
  CampaignImage,
  UploadFileResponse,
  Grant,
  CampaignCommunityProgram,
  InstallmentConfig,
  CommunityConfig,
} from './types';
import { toPaginationParams } from './utils';
import { GrantsImportJob } from './types/ImportJob';
import type { StartImportResponse } from './types/ImportJob';

const getCampaigns = (build: AdminBuild) =>
  build.query<PaginatedResult<Campaign, PaginationKey>, PaginatedQuery<PaginationKey>>({
    providesTags: [TagTypes.Campaign],
    query: (pagination) => ({
      url: `/partnership/campaigns`,
      method: 'GET',
      params: {
        ...toPaginationParams(pagination),
      },
    }),
  });

type CampaignImagesSearchQueryFilter = PaginatedQuery<PaginationKey> & {
  campaignId: string;
};

const getCampaignImages = (build: AdminBuild) =>
  build.query<PaginatedResult<CampaignImage, PaginationKey>, CampaignImagesSearchQueryFilter>({
    providesTags: [TagTypes.CampaignImage],
    query: ({ campaignId, ...pagination }) => ({
      url: `/partnership/campaigns/${campaignId}/images`,
      method: 'GET',
      params: {
        ...toPaginationParams(pagination),
      },
    }),
  });

type CampaignSearchQueryFilter = PaginatedQuery<PaginationKey> & {
  partnerId: string;
};

const getCampaignsByPartner = (build: AdminBuild) =>
  build.query<PaginatedResult<Campaign, PaginationKey>, CampaignSearchQueryFilter>({
    providesTags: [TagTypes.Campaign],
    query: ({ partnerId, ...pagination }) => ({
      url: `/partnership/campaigns/byPartner`,
      method: 'GET',
      params: {
        partnerId,
        ...toPaginationParams(pagination),
      },
    }),
  });

const getCampaign = (build: AdminBuild) =>
  build.query<
    { campaign: Campaign; programs: Array<CampaignCommunityProgram> },
    { campaignId: string }
  >({
    providesTags: [TagTypes.Campaign],
    query: ({ campaignId }) => `/partnership/campaigns/${campaignId}`,
  });

const uploadCampaignImage = (build: AdminBuild) =>
  build.mutation<
    UploadFileResponse,
    { campaignId: string; key: string; alt: string; originalFileName: string; contentType: string }
  >({
    query: ({ campaignId, key, alt, originalFileName, contentType }) => ({
      url: `/partnership/campaigns/${campaignId}/images`,
      method: 'POST',
      body: {
        contentType,
        key,
        alt,
        originalFileName,
      },
    }),
    invalidatesTags: [TagTypes.CampaignImage, TagTypes.CampaignPredefinedKeys],
  });

const getCampaignPredefinedKeys = (build: AdminBuild) =>
  build.query<Array<string>, { campaignId: string }>({
    providesTags: [TagTypes.CampaignPredefinedKeys],
    query: ({ campaignId }) => ({
      url: `/partnership/campaigns/${campaignId}/images/predefinedKeys`,
      method: 'GET',
    }),
    transformResponse: (response: { data: string[] }) => response.data,
  });

type CreateCampaignParams = Omit<
  Campaign,
  | 'PK'
  | 'createdAt'
  | 'updatedAt'
  | 'pendingGrantsCount'
  | 'activeGrantsCount'
  | 'inactiveGrantsCount'
  | 'createdGrantsCount'
  | 'amount'
  | 'campaignId'
  | 'partnerName'
>;
const createCampaign = (build: AdminBuild) =>
  build.mutation<void, CreateCampaignParams>({
    query: ({
      partnerId,
      name,
      code,
      stackwellFee,
      hasEndDate,
      activeUntil,
      installmentsConfig,
      hasCommunity,
      communityConfig,
    }) => ({
      url: `/partnership/campaigns`,
      method: 'POST',
      body: {
        partnerId,
        name,
        code,
        stackwellFee,
        hasEndDate,
        activeUntil,
        installmentsConfig,
        hasCommunity,
        communityConfig,
      },
    }),
    invalidatesTags: [TagTypes.Campaign],
  });

const startCommunityProgram = (build: AdminBuild) =>
  build.mutation<void, { campaignId: string; communityId: string }>({
    query: ({ communityId, campaignId }) => ({
      url: `/partnership/campaigns/${campaignId}/communityProgram/${communityId}/start`,
      method: 'PUT',
    }),
    invalidatesTags: [TagTypes.Campaign],
  });

const pauseCommunityProgram = (build: AdminBuild) =>
  build.mutation<void, { campaignId: string; communityId: string }>({
    query: ({ communityId, campaignId }) => ({
      url: `/partnership/campaigns/${campaignId}/communityProgram/${communityId}/pause`,
      method: 'PUT',
    }),
    invalidatesTags: [TagTypes.Campaign],
  });

const finishCommunityProgram = (build: AdminBuild) =>
  build.mutation<void, { campaignId: string; communityId: string }>({
    query: ({ communityId, campaignId }) => ({
      url: `/partnership/campaigns/${campaignId}/communityProgram/${communityId}/finish`,
      method: 'PUT',
    }),
    invalidatesTags: [TagTypes.Campaign],
  });

type CampaignTextsPK = {
  campaignId: string;
};

type UpdateCampaignTextsParams = CampaignTextsPK & {
  texts: CampaignTexts;
};
const updateCampaignTexts = (build: AdminBuild) =>
  build.mutation<void, UpdateCampaignTextsParams>({
    query: ({ campaignId, texts }) => ({
      url: `/partnership/campaigns/${campaignId}/texts`,
      method: 'PUT',
      body: texts,
    }),
    invalidatesTags: [TagTypes.CampaignTexts],
  });

type CampaignTextsResponse = {
  texts: CampaignTexts;
};
const getCampaignTexts = (build: AdminBuild) =>
  build.query<CampaignTextsResponse, CampaignTextsPK>({
    providesTags: [TagTypes.CampaignTexts],
    query: ({ campaignId }) => ({
      url: `/partnership/campaigns/${campaignId}/texts`,
    }),
  });

const deleteCampaignImage = (build: AdminBuild) =>
  build.mutation<UploadFileResponse, { campaignId: string; key: string }>({
    query: ({ campaignId, key }) => ({
      url: `/partnership/campaigns/${campaignId}/images`,
      method: 'DELETE',
      body: {
        key,
      },
    }),
    invalidatesTags: [TagTypes.CampaignImage, TagTypes.CampaignPredefinedKeys],
  });

type CampaignGrantsImportJobsFilter = PaginatedQuery<PaginationKey> & {
  campaignId: string;
};
const getCampaignGrantsImportJobs = (build: AdminBuild) =>
  build.query<PaginatedResult<GrantsImportJob, PaginationKey>, CampaignGrantsImportJobsFilter>({
    query: ({ campaignId, ...pagination }) => ({
      url: `/partnership/campaigns/${campaignId}/grants/import`, //PartnerName not needed
      method: 'GET',
      params: {
        ...toPaginationParams(pagination),
      },
    }),
  });

type StartGrantsImportResponse = StartImportResponse & {
  job: GrantsImportJob;
};

// TODO maybe move to grants controller.
const startGrantsImport = (build: AdminBuild) =>
  build.mutation<StartGrantsImportResponse, { campaignId: string; originalFileName: string }>({
    query: ({ campaignId, originalFileName }) => ({
      url: `/partnership/campaigns/${campaignId}/grants/import`,
      method: 'POST',
      body: { originalFileName },
    }),
  });

type GrantSearchQueryFilter = PaginatedQuery<PaginationKey> & {
  campaignId: string;
  email: string;
};
const getCampaignGrants = (build: AdminBuild) =>
  build.query<PaginatedResult<Grant, PaginationKey>, GrantSearchQueryFilter>({
    providesTags: [TagTypes.Grants],
    query: ({ campaignId, email, ...pagination }) => ({
      url: `/partnership/campaigns/${campaignId}/grants`,
      method: 'GET',
      params: {
        email,
        ...toPaginationParams(pagination),
      },
    }),
  });

const updateCampaign = (build: AdminBuild) =>
  build.mutation<
    void,
    {
      campaignId: string;
      name: string;
      code: string;
      installmentsConfig: InstallmentConfig[];
      hasCommunity: boolean;
      communityConfig?: CommunityConfig;
    }
  >({
    query: ({ campaignId, name, code, installmentsConfig, hasCommunity, communityConfig }) => ({
      url: `/partnership/campaigns/${campaignId}`,
      method: 'PUT',
      body: {
        name,
        code,
        installmentsConfig,
        hasCommunity,
        communityConfig,
      },
    }),
    invalidatesTags: [TagTypes.Campaign],
  });

export {
  createCampaign,
  getCampaign,
  getCampaigns,
  getCampaignPredefinedKeys,
  getCampaignsByPartner,
  getCampaignImages,
  uploadCampaignImage,
  updateCampaignTexts,
  getCampaignTexts,
  deleteCampaignImage,
  getCampaignGrantsImportJobs,
  startGrantsImport,
  getCampaignGrants,
  startCommunityProgram,
  pauseCommunityProgram,
  finishCommunityProgram,
  updateCampaign,
};
