/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  environment: 'production',
  mixpanelToken: '60f9d12d94b797086f785b5f54debc03',
  aws_project_region: 'us-east-2',
  aws_appsync_graphqlEndpoint:
    'https://fh5rcj6jefe6jbj3rqyu4ijt64.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-v5y3oy4635da7nimt5bbndiavi',
  aws_cloud_logic_custom: [
    {
      name: 'stackwellPlaidIntegration',
      endpoint: 'https://7quh30bxmj.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'verifyUserEmail',
      endpoint: 'https://cc5864jm4g.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'userActions',
      endpoint: 'https://skl81roosc.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'apex',
      endpoint: 'https://3g6ixulgl8.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'cash',
      endpoint: 'https://6tsg2pewzd.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'stock',
      endpoint: 'https://peyq8m9gwc.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'portfolios',
      endpoint: 'https://pxwcxkn22l.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'apexDocuments',
      endpoint: 'https://kkhfeyc8wd.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'admin',
      endpoint: 'https://acvr46nx30.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
    {
      name: 'stackwellNotification',
      endpoint: 'https://bpi8eawwa1.execute-api.us-east-2.amazonaws.com/prod',
      region: 'us-east-2',
    },
  ],
  aws_dynamodb_all_tables_region: 'us-east-2',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'apex_user_info-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'plaid_info-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'transfers-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'transferSchedule-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'apexProposals-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'apex_event_info-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'apexAnalysisErrors-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'apexLiquidations-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'apexDocuments-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'stackwell_queue-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'apexAccountForms-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'apexBalanceHistory-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'apex_event_api_info-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'stripeCustomerInfo-prod',
      region: 'us-east-2',
    },
    {
      tableName: 'stackwellNotification-prod',
      region: 'us-east-2',
    },
  ],
  aws_cognito_region: 'us-east-2',
  aws_user_pools_id: 'us-east-2_TrPBNXzcu',
  aws_user_pools_web_client_id: '3nbkvis031i19v05p7bi1chdm7',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL', 'PHONE_NUMBER'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['PHONE_NUMBER'],
};

export default awsmobile;
