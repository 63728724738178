import { handleActions, combineActions } from 'redux-actions';

import { authenticationActions } from '../actions/authentication';
import { GROUP_ADMIN, GROUP_SUPER_ADMIN } from '../constants/cognito';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  isAdmin: false,
  isSuperAdmin: false,
  currentUser: null,
  token: null,
};

const userReducer = handleActions(
  {
    [authenticationActions.login]: (state) => {
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
        isAdmin: false,
        isSuperAdmin: false,
        currentUser: null,
        token: null,
      };
    },
    [combineActions(authenticationActions.logout, authenticationActions.loginFailure)]: (state) => {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isAdmin: false,
        isSuperAdmin: false,
        currentUser: null,
        token: null,
      };
    },
    [authenticationActions.setAuthenticatedUser]: (state, { payload }) => {
      let isAdmin = false;
      let isSuperAdmin = false;
      const groups = payload.signInUserSession.accessToken.payload['cognito:groups'];
      if (groups) {
        isAdmin = groups.indexOf(GROUP_ADMIN) !== -1;
        isSuperAdmin = groups.indexOf(GROUP_SUPER_ADMIN) !== -1;
      }
      return {
        ...state,
        currentUser: {
          ...payload.attributes,
          username: payload.username,
        },
        isAuthenticated: true,
        isLoading: false,
        isAdmin,
        isSuperAdmin,
        token: payload.signInUserSession.accessToken.jwtToken,
      };
    },
  },
  initialState,
);

export default userReducer;
