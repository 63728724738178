import { handleActions } from 'redux-actions';

import { bundlesActions } from '../actions/bundles';

const initialState = {
  bundlesById: {},
};

const bundlesReducer = handleActions(
  {
    [bundlesActions.loadAllBundlesSuccess]: (state, { payload }) => {
      const remappedBundles = payload.reduce((obj, entry) => ({ ...obj, [entry.id]: entry }), {});
      return {
        ...state,
        bundlesById: { ...remappedBundles },
      };
    },
    [bundlesActions.loadAllBundlesFailure]: (state) => {
      return {
        ...state,
        bundlesById: {},
      };
    },
  },
  initialState,
);

export default bundlesReducer;
