import { PaginationKey } from './types';

export const toPaginationParams = ({
  exclusiveStartKey,
  limit,
}: {
  exclusiveStartKey?: PaginationKey;
  limit: number;
}) => {
  const params: Record<string, string> = { limit: String(limit) };

  exclusiveStartKey &&
    Object.keys(exclusiveStartKey).forEach(
      (key) => (params[`lastEvaluatedKey.${key}`] = exclusiveStartKey[key]),
    );
  return params;
};
