import { createActions } from 'redux-actions';

const authenticationActions = createActions({
  LOGIN: (username, password) => ({ username, password }),
  LOGOUT: undefined,
  LOGIN_SUCCESS: undefined,
  LOGIN_FAILURE: undefined,
  UPDATE_DATA: undefined,
  SET_AUTHENTICATED_USER: undefined,
  LOAD_CURRENT_USER: undefined,
  LOAD_CURRENT_USER_SUCCESS: undefined,
  LOAD_CURRENT_USER_FAILURE: undefined,
});

export { authenticationActions };
