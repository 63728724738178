import { takeEvery, call, put, select, all } from 'redux-saga/effects';
import { API } from 'aws-amplify';

import { modelsActions } from '../actions/models';
import { uiActions } from '../actions/ui';

function* loadModel(id, headers) {
  const result = yield call([API, API.get], 'portfolios', `/portfolios/models/${id}`, { headers });
  return result;
}

function* watchLoadAllModels() {
  yield takeEvery(modelsActions.loadAllModels, function* (action) {
    try {
      yield put(uiActions.setLoading(action.type));
      const { token } = yield select((state) => state.authentication);
      const headers = {
        Authorization: token,
      };
      const result = yield call([API, API.get], 'portfolios', '/portfolios/models', { headers });
      const allModels = yield all(result.map((model) => call(loadModel, model.id, headers)));
      yield put(modelsActions.loadAllModelsSuccess(allModels));
    } catch (err) {
      const message = 'Unable to load models';
      yield put(uiActions.showErrorMessage(message));
      yield put(modelsActions.loadAllModelsFailure(message));
    } finally {
      yield put(uiActions.finishLoading(action.type));
    }
  });
}

export default watchLoadAllModels;
