import { handleActions } from 'redux-actions';

import { modelsActions } from '../actions/models';

const initialState = {
  modelsById: {},
};

const modelsReducer = handleActions(
  {
    [modelsActions.loadAllModelsSuccess]: (state, { payload }) => {
      const remappedModels = payload.reduce((obj, entry) => ({ ...obj, [entry.id]: entry }), {});
      return {
        ...state,
        modelsById: { ...remappedModels },
      };
    },
    [modelsActions.loadAllModelsFailure]: (state) => {
      return {
        ...state,
        modelsById: {},
      };
    },
  },
  initialState,
);

export default modelsReducer;
