import { Backdrop, CircularProgress, Snackbar } from '@mui/material';
import { Provider, useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';

import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import { appActions } from './actions/app';
import { store } from './store';
import { makeStyles } from '@mui/styles';
import theme from './theme';
import { uiActions } from './actions/ui';
import uiSelectors from './selectors/ui';

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1 },
}));

const RootWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const isLoading = useSelector(uiSelectors.isLoadingSelector);
  const notifications = useSelector(uiSelectors.notificationsSelector);
  const app = useSelector((state) => state.app);
  useEffect(() => {
    dispatch(appActions.appStart());
  }, [dispatch]);
  return (
    <>
      <Backdrop open={isLoading || app.isLoading} className={styles.backdrop}>
        <CircularProgress />
      </Backdrop>
      {app.isLoaded && children}
      {/* todo: show Snackbar properly */}
      {notifications.map((notification) => (
        <Snackbar
          open
          onClose={() => {
            dispatch(uiActions.closeMessage(notification.id));
          }}
          autoHideDuration={4000}
          key={notification.id}
        >
          <Alert
            onClose={() => {
              dispatch(uiActions.closeMessage(notification.id));
            }}
            severity={notification.severity}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};

export const rootWrapper = ({ element }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RootWrapper>{element}</RootWrapper>
      </ThemeProvider>
    </Provider>
  );
};
