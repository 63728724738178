import { takeEvery, call, put, select, all } from 'redux-saga/effects';
import { API } from 'aws-amplify';

import { bundlesActions } from '../actions/bundles';
import { uiActions } from '../actions/ui';

function* loadModel(id, headers) {
  const result = yield call([API, API.get], 'portfolios', `/portfolios/bundles/${id}`, { headers });
  return result;
}

function* watchLoadAllBundles() {
  yield takeEvery(bundlesActions.loadAllBundles, function* (action) {
    try {
      yield put(uiActions.setLoading(action.type));
      const { token } = yield select((state) => state.authentication);
      const headers = {
        Authorization: token,
      };
      const result = yield call([API, API.get], 'portfolios', '/portfolios/bundles', { headers });
      const allBundles = yield all(result.map((model) => call(loadModel, model.id, headers)));
      yield put(bundlesActions.loadAllBundlesSuccess(allBundles));
    } catch (err) {
      const message = 'Unable to load bundles';
      yield put(uiActions.showErrorMessage(message));
      yield put(bundlesActions.loadAllBundlesFailure(message));
    } finally {
      yield put(uiActions.finishLoading(action.type));
    }
  });
}

export default watchLoadAllBundles;
