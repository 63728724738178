import { createSelector } from 'reselect';

export const uiSelector = (state) => state.ui;

export const isLoadingSelector = createSelector(uiSelector, (ui) => {
  let isLoading = false;
  Object.keys(ui.isLoading).forEach((el) => {
    isLoading = isLoading || ui.isLoading[el];
  });
  return isLoading;
});

export const notificationsSelector = (state) => state.ui.notifications;

export default {
  isLoadingSelector,
  notificationsSelector,
};
