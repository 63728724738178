import { handleActions, combineActions } from 'redux-actions';

import { uiActions } from '../actions/ui';

const initialState = {
  isLoading: {},
  notifications: [],
};

const uiReducer = handleActions(
  {
    [uiActions.setLoading]: (state, { payload }) => ({
      ...state,
      isLoading: { ...state.isLoading, [payload]: true },
    }),
    [uiActions.finishLoading]: (state, { payload }) => ({
      ...state,
      isLoading: { ...state.isLoading, [payload]: false },
    }),
    [combineActions(
      uiActions.showInfoMessage,
      uiActions.showErrorMessage,
      uiActions.showWarningMessage,
      uiActions.showSuccessMessage,
    )]: (state, { payload }) => {
      const { message, severity } = payload;
      const id = Date.now();
      const newMessage = { message, severity, id };
      return {
        ...state,
        notifications: [...state.notifications, newMessage],
      };
    },
    [uiActions.closeMessage]: (state, { payload }) => {
      return {
        ...state,
        notifications: state.notifications.filter((el) => el.id !== payload),
      };
    },
  },
  initialState,
);

export default uiReducer;
