import { handleActions } from 'redux-actions';

import { appActions } from '../actions/app';

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: false,
};

const appReducer = handleActions(
  {
    [appActions.appStart]: (state) => {
      return { ...state, isLoading: true, isLoaded: false, error: false };
    },
    [appActions.appStartSuccess]: (state) => {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: false,
      };
    },
    [appActions.appStartFailure]: (state, { payload }) => {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        error: payload,
      };
    },
  },
  initialState,
);

export default appReducer;
