import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { Auth } from 'aws-amplify';
import { authenticationActions } from '../actions/authentication';
import { navigate } from 'gatsby';
import { uiActions } from '../actions/ui';

const serializeObject = (o) => JSON.parse(JSON.stringify(o));

function* setCurrentUser() {
  const currentUser = yield call([Auth, Auth.currentAuthenticatedUser]);
  yield put(authenticationActions.setAuthenticatedUser(serializeObject(currentUser)));
}

function* watchLoginSaga() {
  yield takeLatest(authenticationActions.login, function* (action) {
    const { username, password } = action.payload;
    try {
      yield call([Auth, Auth.signIn], username, password);
      yield call(setCurrentUser);
      yield put(authenticationActions.loginSuccess());
      yield call(navigate, '/app/users');
    } catch (err) {
      console.error(err);
      yield put(uiActions.showErrorMessage(err.message));
      yield put(authenticationActions.loginFailure(err.message));
    }
  });
}

function* watchLogoutSaga() {
  yield takeLatest(authenticationActions.logout, function* () {
    try {
      yield call([Auth, Auth.signOut]);
      yield call(navigate, '/app/login');
    } catch (err) {
      console.err(err);
    }
  });
}

function* watchLoadCurrentUserSaga() {
  yield takeEvery(authenticationActions.loadCurrentUser, function* () {
    try {
      yield call(setCurrentUser);
      yield put(authenticationActions.loadCurrentUserSuccess());
    } catch (err) {
      yield put(authenticationActions.loadCurrentUserFailure());
      yield put(authenticationActions.logout());
    }
  });
}

function* authenticationSaga() {
  yield all([fork(watchLoginSaga), fork(watchLogoutSaga), fork(watchLoadCurrentUserSaga)]);
}

export default authenticationSaga;
