import { createActions } from 'redux-actions';

const uiActions = createActions({
  SET_LOADING: undefined,
  FINISH_LOADING: undefined,
  SHOW_INFO_MESSAGE: (message) => ({ message, severity: 'info' }),
  SHOW_WARNING_MESSAGE: (message) => ({ message, severity: 'warning' }),
  SHOW_ERROR_MESSAGE: (message) => ({ message, severity: 'error' }),
  SHOW_SUCCESS_MESSAGE: (message) => ({ message, severity: 'success' }),
  CLOSE_MESSAGE: undefined,
});

export { uiActions };
