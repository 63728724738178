import { all, fork } from 'redux-saga/effects';
import appSaga from './app';
import authenticationSaga from './authentication';
import modelsSaga from './models';
import bundlesSaga from './bundles';

export default function* root() {
  yield fork(appSaga);
  yield all([fork(authenticationSaga), fork(modelsSaga), fork(bundlesSaga)]);
}
