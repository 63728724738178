import authentication from './authentication';
import app from './app';
import ui from './ui';
import models from './models';
import bundles from './bundles';

const reducers = {
  authentication,
  app,
  ui,
  models,
  bundles,
};

export default reducers;
